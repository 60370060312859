import { createSlice } from '@reduxjs/toolkit';
import davApi from '../../../api/api';

const API_URL = '/v1/customer';

const initialState = {
  customers: [],
  customerContent: null,
  customerSearch: '',
  editCustomer: false,
  currentFilter: 'show_all',
};

export const CustomerStore = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getCustomers: (state, action) => {
      state.customers = action.payload;
    },
    SearchCustomer: (state, action) => {
      state.customerSearch = action.payload.toLocaleLowerCase();
    },
    SelectCustomer: (state, action) => {
      state.customerContent = action.payload;
    },
    DeleteCustomer: (state, action) => {
      const index = state.customers.findIndex((customer) => customer.id === action.payload);
      state.customers.splice(index, 1);
    },
    isEdit: (state) => {
      state.editCustomer = !state.editCustomer;
    },
    setVisibilityFilter: (state, action) => {
      state.currentFilter = action.payload;
    },
    UpdateCustomer: (state, action) => {
      const index = state.customers.findIndex((cust) => cust.id === action.payload.id);
      state.customers[index] = action.payload;
    },
    addCustomer: (state, action) => {
      state.customers.push(action.payload);
    },
  },
});

export const {
  getCustomers,
  SearchCustomer,
  isEdit,
  SelectCustomer,
  DeleteCustomer,
  UpdateCustomer,
  addCustomer,
  setVisibilityFilter,
} = CustomerStore.actions;

export const fetchCustomers = async () => {
  try {
    const response = await davApi.get(`${API_URL}`);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchandSaveCustomerData = () => async (dispatch) => {
  try {
    const response = await davApi.get(`${API_URL}`);
    if (response.data?.data) {
      dispatch(getCustomers(response.data.data));
    } else {
      throw new Error(response.data?.error || response.data?.message);
    }
    return true;
  } catch (err) {
    throw new Error(err?.message);
  }
};

export const addCustomerApi = async (collection) => {
  try {
    const response = await davApi.post(`${API_URL}`, collection);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateCustomerApi = async (id, collection) => {
  try {
    const response = await davApi.patch(`${API_URL}/${id}`, collection);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteCustomerApi = async (id) => {
  try {
    const response = await davApi.delete(`${API_URL}/${id}`);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const searchCustomerContact = async (name) => {
  try {
    const response = await davApi.get(`/v1/xero/contact?name=${name}`);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getOneContact = async (id) => {
  try {
    const response = await davApi.get(`/v1/xero/contact/${id}`);
    let result = null;
    if (Array.isArray(response.data?.data) && response.data.data.length > 0) {
      result = response.data.data;
    } else {
      throw new Error('Error while fetching contact data!');
    }
    return result[0];
  } catch (err) {
    throw new Error(err);
  }
};

export const searchCustomerAccount = async (name, type) => {
  try {
    const response = await davApi.get(`/v1/xero/account?name=${name}&type=${type}`);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getOneAccount = async (id) => {
  try {
    const response = await davApi.get(`/v1/xero/account/${id}`);
    let result = null;
    if (Array.isArray(response.data?.data) && response.data.data.length > 0) {
      result = response.data.data;
    } else {
      throw new Error('Error while fetching account data!');
    }
    return result[0];
  } catch (err) {
    throw new Error(err);
  }
};

export const promiseCustomerOptions = (q) => new Promise((resolve, reject) => {
  davApi.get(`/v1/customer/search?q=${q}`).then((data) => {
      if (data.status === 200) {
          resolve(data.data?.data?.map((cust) => { return { label: cust.name, value: cust.id } }));
      } else {
          reject(data.data?.error || data.data?.message || 'Error!!');
      }
  });
});

export default CustomerStore.reducer;
