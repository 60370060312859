import { createSlice } from '@reduxjs/toolkit';
import davApi from '../../../api/api';

const API_URL = '/v1/utils/apipartners';

const initialState = {
    data: []
};

export const APIPartnerStore = createSlice({
    name: 'apipartner',
    initialState,
    reducers: {
        getData: (state, action) => {
            state.data = action.payload;
        }
    },
});

export const {
    getData
} = APIPartnerStore.actions;

export const fetchandSaveAPIPartnerData = () => async (dispatch) => {
    try {
        const response = await davApi.get(`${API_URL}`);
        if (response.data?.data) {
            dispatch(getData(response.data.data));
        } else {
            throw new Error(response.data?.error || response.data?.message);
        }
        return true;
    } catch (err) {
        throw new Error(err?.message);
    }
};

export default APIPartnerStore.reducer;