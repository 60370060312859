import { createSlice } from '@reduxjs/toolkit';
import davApi from '../../api/api';

const initialState = {
    user: null,
    sidebarmenu: [],
    token: null
};

export const AuthStore = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, action) => {
            localStorage.setItem('auth', JSON.stringify(action.payload));
            state.user = action.payload.user;
            state.token = action.payload.token;
            davApi.defaults.headers.common.Authorization = `Bearer ${action.payload.token}`;
        },
        setMenu: (state, action) => {
            state.sidebarmenu = action.payload;
        },
        DeleteAuth: (state) => {
            localStorage.removeItem('auth');
            state.user = null;
            state.token = null;
            davApi.defaults.headers.common.Authorization = null;
        },
    },
});

export const {
    setAuth,
    setMenu,
    DeleteAuth
} = AuthStore.actions;

export const fetchMenu = (id) => async (dispatch) => {
    try {
        const response = await davApi.get(`/v1/user/shownmenu/${id}`);
        dispatch(setMenu(response.data?.data));
    } catch (err) {
        throw new Error(err);
    }
};

export default AuthStore.reducer;
