import { createSlice } from '@reduxjs/toolkit';
import davApi from '../../api/api';

const API_URL = '/v1/inventory';

const initialState = {
    data: [],
    meta: {},
    content: null,
    search: '',
    isEdit: false,
    currentFilter: 'show_all',
};

export const InventoryStore = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        getData: (state, action) => {
            state.data = action.payload;
        },
        getMeta: (state, action) => {
            state.meta = action.payload;
        },
        searchData: (state, action) => {
            state.search = action.payload.toLocaleLowerCase();
        },
        selectData: (state, action) => {
            state.content = action.payload;
        },
        deleteData: (state, action) => {
            const index = state.data.findIndex((i) => i.id === action.payload);
            state.data.splice(index, 1);
        },
        isEdit: (state) => {
            state.isEdit = !state.isEdit;
        },
        setVisibilityFilter: (state, action) => {
            state.currentFilter = action.payload;
        },
        updateData: (state, action) => {
            const index = state.data.findIndex((i) => i.id === action.payload.id);
            state.data[index] = action.payload;
        },
        addData: (state, action) => {
            state.data.push(action.payload);
        },
    },
});

export const {
    getData,
    getMeta,
    searchData,
    isEdit,
    selectData,
    deleteData,
    updateData,
    addData,
    setVisibilityFilter,
} = InventoryStore.actions;

export const fetchData = async (qs) => {
    try {
        const response = await davApi.get(`${API_URL}?${qs}`);
        return response;
    } catch (err) {
        throw new Error(err);
    }
};

export const fetchDataDetail = async (code) => {
    try {
        const response = await davApi.get(`${API_URL}/${code}`);
        return response;
    } catch (err) {
        throw new Error(err);
    }
};

export const fetchandSaveInventoryData = () => async (dispatch) => {
    try {
        const response = await davApi.get(`${API_URL}`);
        if (response.data?.data) {
            dispatch(getData(response.data.data));
        } else {
            throw new Error(response.data?.error || response.data?.message);
        }
        return true;
    } catch (err) {
        throw new Error(err?.message);
    }
};

export const addDataApi = async (collection) => {
    try {
        const response = await davApi.post(`${API_URL}`, collection);
        return response.data;
    } catch (err) {
        throw new Error(err);
    }
};

export const updateDataApi = async (id, collection) => {
    try {
        const response = await davApi.patch(`${API_URL}/${id}`, collection);
        return response.data;
    } catch (err) {
        throw new Error(err);
    }
};

export const deleteDataApi = async (id) => {
    try {
        const response = await davApi.delete(`${API_URL}/${id}`);
        return response.data;
    } catch (err) {
        throw new Error(err);
    }
};

export default InventoryStore.reducer;