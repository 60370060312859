import { createSlice } from '@reduxjs/toolkit';
import davApi from '../../../api/api';

const API_URL = '/v1/utils';

const initialState = {
    pnrStatus: [],
    pnrTypes: [],
};

export const PnrStore = createSlice({
    name: 'pnr',
    initialState,
    reducers: {
        getStatus: (state, action) => {
            state.pnrStatus = action.payload;
        },
        getType: (state, action) => {
            state.pnrTypes = action.payload;
        },
    },
});

export const {
    getStatus,
    getType
} = PnrStore.actions;

export const fetchPnrStatus = () => async (dispatch) => {
    try {
        const response = await davApi.get(`${API_URL}/pnrstatus`);
        if (response.data?.data) {
            dispatch(getStatus(response.data.data));
        } else {
            throw new Error(response.data?.error || response.data?.message);
        }
        return true;
    } catch (err) {
        throw new Error(err?.message);
    }
};

export const fetchPnrType = () => async (dispatch) => {
    try {
        const response = await davApi.get(`${API_URL}/pnrtype`);
        if (response.data?.data) {
            dispatch(getType(response.data.data));
        } else {
            throw new Error(response.data?.error || response.data?.message);
        }
        return true;
    } catch (err) {
        throw new Error(err?.message);
    }
};

export default PnrStore.reducer;