import { createSlice } from '@reduxjs/toolkit';
import davApi from '../../api/api';

const API_URL = '/v1/user';

const initialState = {
    users: [],
    userContent: null,
    userSearch: '',
    editUser: false,
    currentFilter: 'show_all',
};

export const UserStore = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getUsers: (state, action) => {
            state.users = action.payload;
        },
        SearchUser: (state, action) => {
            state.userSearch = action.payload.toLocaleLowerCase();
        },
        SelectUser: (state, action) => {
            state.userContent = action.payload;
        },
        DeleteUser: (state, action) => {
            const index = state.users.findIndex((user) => user.id === action.payload);
            state.users.splice(index, 1);
        },
        isEdit: (state) => {
            state.editUser = !state.editUser;
        },
        setVisibilityFilter: (state, action) => {
            state.currentFilter = action.payload;
        },
        UpdateUser: {
            reducer: (state, action) => {
                const index = state.users.findIndex((user) => user.id === action.payload.id);
                state.users[index] = action.payload;
            },
            prepare: (
                id,
                username,
                name,
                email,
                description,
                status,
                isApproved
            ) => {
                return {
                    payload: {
                        id,
                        username,
                        name,
                        email,
                        description,
                        status,
                        isApproved
                    },
                };
            },
        },
        addUser: {
            reducer: (state, action) => {
                state.users.push(action.payload);
            },
            prepare: (
                id,
                username,
                name,
                email,
                description,
                status,
                isApproved
            ) => {
                return {
                    payload: {
                        id,
                        username,
                        name,
                        email,
                        description,
                        status,
                        isApproved
                    },
                };
            },
        },
    },
});

export const {
    getUsers,
    SearchUser,
    isEdit,
    SelectUser,
    DeleteUser,
    UpdateUser,
    addUser,
    setVisibilityFilter,
} = UserStore.actions;

export const fetchUsers = async () => {
    try {
        const response = await davApi.get(`${API_URL}`);
        return response.data;
    } catch (err) {
        throw new Error(err);
    }
};

export const addUserApi = async (collection) => {
    try {
        const response = await davApi.post(`${API_URL}`, collection);
        return response.data;
    } catch (err) {
        throw new Error(err);
    }
};

export const updateUserApi = async (id, collection) => {
    try {
        const response = await davApi.patch(`${API_URL}/${id}`, collection);
        return response.data;
    } catch (err) {
        throw new Error(err);
    }
};

export const deleteUserApi = async (id) => {
    try {
        const response = await davApi.delete(`${API_URL}/${id}`);
        return response.data;
    } catch (err) {
        throw new Error(err);
    }
};

export default UserStore.reducer;
