import { createSlice } from '@reduxjs/toolkit';
import davApi from '../../../api/api';

const API_URL = '/v1/utils';

const initialState = {
    emdTypes: [],
};

export const EmdStore = createSlice({
    name: 'emd',
    initialState,
    reducers: {
        getType: (state, action) => {
            state.emdTypes = action.payload;
        },
    },
});

export const {
    getType
} = EmdStore.actions;

export const fetchEmdType = () => async (dispatch) => {
    try {
        const response = await davApi.get(`${API_URL}/emdtype`);
        if (response.data?.data) {
            dispatch(getType(response.data.data));
        } else {
            throw new Error(response.data?.error || response.data?.message);
        }
        return true;
    } catch (err) {
        throw new Error(err?.message);
    }
};

export default EmdStore.reducer;