import { createSlice } from '@reduxjs/toolkit';
import davApi from '../../../api/api';

const API_URL = '/v1/utils/cabinclass';

const initialState = {
    data: [{
        id: 'eco',
        name: 'ECONOMY'
    }, {
        id: 'bis',
        name: 'BUSSINESS'
    }],
};

export const CabinClassStore = createSlice({
    name: 'cabinClass',
    initialState,
    reducers: {
        getData: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const {
    getData,
} = CabinClassStore.actions;

export const fetchData = async () => {
    try {
        const response = await davApi.get(`${API_URL}`);
        return response;
    } catch (err) {
        throw new Error(err);
    }
};

export default CabinClassStore.reducer;