import { createSlice } from '@reduxjs/toolkit';
import davApi from '../../api/api';

const API_URL = '/v1/bookingquo';

const initialState = {
    data: [],
    meta: {},
    bquofilter: {},
    content: null,
    prevBquo: null,
    dataDetail: null,
    search: '',
    isEdit: false,
    currentFilter: 'show_all',
    itinbquoitemidx: null,
    itinselected: {}
};

export const BookingquoStore = createSlice({
    name: 'bookingquo',
    initialState,
    reducers: {
        getData: (state, action) => {
            state.data = action.payload;
        },
        getMeta: (state, action) => {
            state.meta = action.payload;
        },
        getBquofilter: (state, action) => {
            state.bquofilter = action.payload;
        },
        searchData: (state, action) => {
            state.search = action.payload.toLocaleLowerCase();
        },
        selectData: (state, action) => {
            state.content = action.payload;
        },
        selectDetailData: (state, action) => {
            state.dataDetail = action.payload;
        },
        selectPrevData: (state, action) => {
            state.prevBquo = action.payload;
        },
        deleteData: (state, action) => {
            const index = state.data.findIndex((i) => i.id === action.payload);
            state.data.splice(index, 1);
        },
        isEdit: (state) => {
            state.isEdit = !state.isEdit;
        },
        setVisibilityFilter: (state, action) => {
            state.currentFilter = action.payload;
        },
        updateData: (state, action) => {
            const index = state.data.findIndex((i) => i.id === action.payload.id);
            state.data[index] = action.payload;
        },
        addData: (state, action) => {
            state.data.push(action.payload);
        },
        showModalItin: {
            reducer: (state, action) => {
                state.itinbquoitemidx = action.payload.index;
                state.itinselected = action.payload.value;
            },
            prepare: (
                itemidx,
                selectedvalue
            ) => {
                return {
                    payload: {
                        index: itemidx,
                        value: selectedvalue
                    },
                };
            },
        }
    },
});

export const {
    getData,
    getMeta,
    getBquofilter,
    searchData,
    isEdit,
    selectData,
    selectDetailData,
    selectPrevData,
    deleteData,
    updateData,
    addData,
    setVisibilityFilter,
    showModalItin
} = BookingquoStore.actions;

export const fetchData = async (qs) => {
    try {
        const response = await davApi.get(`${API_URL}?${qs}`);
        return response;
    } catch (err) {
        throw new Error(err);
    }
};

export const fetchItemData = async (qs) => {
    try {
        const response = await davApi.get(`${API_URL}/items?${qs}`);
        return response;
    } catch (err) {
        throw new Error(err);
    }
};

export const fetchDataDetail = async (code) => {
    try {
        const response = await davApi.get(`${API_URL}/${code}`);
        return response;
    } catch (err) {
        throw new Error(err);
    }
};

export const addDataApi = async (collection) => {
    try {
        const response = await davApi.post(`${API_URL}`, collection);
        return response.data;
    } catch (err) {
        throw new Error(err);
    }
};

export const updateDataApi = async (id, collection) => {
    try {
        const response = await davApi.patch(`${API_URL}/${id}`, collection);
        return response.data;
    } catch (err) {
        throw new Error(err);
    }
};

export const deleteDataApi = async (id) => {
    try {
        const response = await davApi.delete(`${API_URL}/${id}`);
        return response.data;
    } catch (err) {
        throw new Error(err);
    }
};

export default BookingquoStore.reducer;
