import React, { Suspense, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import { fetchMenu, setAuth } from './store/auth/AuthStore';

const App = () => {
  const routing = useRoutes(Themeroutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);
  const dispatch = useDispatch();

  useEffect(() => {
    moment.tz.setDefault('Asia/Jakarta');

    try {
      const authData = JSON.parse(localStorage.getItem('auth'));
      if (authData) {
        dispatch(setAuth(authData));
        dispatch(fetchMenu(authData.user.id));
      }
    } catch (e) {
      alert(e.message);
    }
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <div
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}
      >
        <ThemeSelector />
        {routing}
      </div>
    </Suspense>
  );
};

export default App;
