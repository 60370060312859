import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './auth/AuthStore';

import UserdataReducer from './administrator/UserStore';

import AirlineReducer from './master/airline/AirlineStore';
import AirportReducer from './master/airport/AirportStore';
import CityReducer from './master/city/CityStore';
import CountryReducer from './master/country/CountryStore';
import CurrencyReducer from './master/currency/CurrencyStore';
import CustomerReducer from './master/customer/CustomerStore';
import CustomerDepositReducer from './finance/DepositStore';
import DepositMappingReducer from './finance/DepositmappingStore';
import SupplierReducer from './master/supplier/SupplierStore';
import XerobankReducer from './master/xerobank/XerobankStore';
import BookingquoReducer from './reservation/BookingquoStore';
import InvoiceReducer from './reservation/InvoiceStore';
import InventoryReducer from './product/InventoryStore';
import PnrReducer from './product/utils/PnrStore';
import PNRTypeReducer from './master/pnrtype/PNRTypeStore';
import APIPartnerReducer from './master/apipartner/APIPartnerStore';
import EmdReducer from './product/utils/EmdStore';
import CabinClassReducer from './master/cabinclass/CabinClassStore';

import NotesReducer from './apps/notes/NotesSlice';
import CustomizerReducer from './customizer/CustomizerSlice';
import ChatsReducer from './apps/chat/ChatSlice';
import ContactsReducer from './apps/contacts/ContactSlice';
import EmailReducer from './apps/email/EmailSlice';
import TicketReducer from './apps/ticket/TicketSlice';

export const store = configureStore({
  reducer: {
    authReducer: AuthReducer,

    userdataReducer: UserdataReducer,

    airlineReducer: AirlineReducer,
    airportReducer: AirportReducer,
    cityReducer: CityReducer,
    countryReducer: CountryReducer,
    currencyReducer: CurrencyReducer,
    customerReducer: CustomerReducer,
    customerDepositReducer: CustomerDepositReducer,
    depositMappingReducer: DepositMappingReducer,
    supplierReducer: SupplierReducer,
    xerobankReducer: XerobankReducer,
    bookingquoReducer: BookingquoReducer,
    invoiceReducer: InvoiceReducer,
    inventoryReducer: InventoryReducer,
    pnrReducer: PnrReducer,
    pnrtypeReducer: PNRTypeReducer,
    apiPartnerReducer: APIPartnerReducer,
    emdReducer: EmdReducer,
    cabinClassReducer: CabinClassReducer,

    customizer: CustomizerReducer,
    notesReducer: NotesReducer,
    chatReducer: ChatsReducer,
    contactsReducer: ContactsReducer,
    emailReducer: EmailReducer,
    ticketReducer: TicketReducer,
  },
});

export default store;
