import axios from "axios";

export const apiUrl = process.env.REACT_APP_API_URL;

const davApi = axios.create({
    baseURL: apiUrl,
    validateStatus: function resstatus(status) {
        if (status >= 200 && status <= 500) return true;
        return false;
    },
});

export default davApi;